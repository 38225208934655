@import "./assets/styles/typography";

* {
  box-sizing: border-box;
  font-family: 'BreeW02';
}

body {
  margin: 0;
}



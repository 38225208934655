@import "./variables";
@import "~foundation-sites/scss/foundation.scss";

.page {
  @include flex;
  @include flex-align(center, center);
  height: 100vh;
  background: $background-login;
  overflow: hidden;

  &::before {
    content: "";
    width: 100%;
    height: 450px;
    position: absolute;
    top: rem-calc(-130);
    transform: skewY(-8deg);
    background: $background-login-ligth;
  }

  $height-inputs: rem-calc(40px);

  .login {
    padding: rem-calc(30px);
    border-radius: 10px;
    background: $white;
    text-align: center;
    width: rem-calc(400px);
    z-index: 10;

    .title {
      font-weight: normal;
      font-size: rem-calc(25px);
      color: $background-login;
      margin: rem-calc(10px) 0 0;
    }

    .subtitle {
      font-size: rem-calc(17px);
      color: $background-login;
      margin: 0 0 rem-calc(20px);
    }

    input {
      background: $background-input;
      font-weight: bold;
      border-radius: 6px;
      width: 100%;
      height: $height-inputs;
      margin: rem-calc(15px) 0;
      padding: 0 rem-calc(10px);
      font-size: rem-calc(15px);
      color: $background-login;
      border: none;
      text-align: center;
      &:focus, &:active {
        box-shadow: none;
        outline: none;
      }
      &::placeholder {
        color: $background-input-placeholder;
      }
    }

    .forgotPassword {
      color: $background-login;
      text-align: right;
    }

    button {
      background: $background-login;
      font-weight: bold;
      border-radius: 15px;
      border: none;
      color: $white;
      width: 70%;
      height: $height-inputs;
      &:focus, &:active {
        box-shadow: none;
        outline: none;
      }
      &:disabled {
        background: $background-input-disabled;
      }
    }

    .error {
      color: $red;
    }
  }
}

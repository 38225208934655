@import "./variables";
@import "../fonts/mixins";
@import "~foundation-sites/scss/foundation.scss";

@include font-face(Acumin, "./AcuminProExtraCond", Semibold, null, ttf);

.navbar {
    height: rem-calc($navbar-height);
    background: $background-login;
    @include flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;

    @include breakpoint(medium down) {
        padding: 0 1rem;
    }

    .appname {
        @include flex;
        align-items: center;
        h3 {
            font-size: rem-calc(34);
            font-weight: normal;
            font-family: 'Acumin';
            @include breakpoint(medium down) {
                display: none;
            }
        }
        img {
            width: rem-calc(50px);
            margin-right: 1rem;
        }
    }
    .rightMenu {
        @include flex;
        align-items: center;
        .profile {
            @include flex;
            align-items: center;
            margin: 1rem;
            img {
                margin-right: 1rem;
                width: rem-calc(50px);
            }
            h3 {
                @include breakpoint(medium down) {
                    display: none;
                }
            }
        }
        .notifications {
            img {
                margin: 0.6rem 0 0;
                width: rem-calc(20px);
            }
        }
    }

    h3 {
        color: $white;
    }
}

@import "./variables";
@import "~foundation-sites/scss/foundation.scss";

.page {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.container {
    height: calc(100% - #{rem-calc($navbar-height)});
    overflow-x: auto;
    @include breakpoint(large) {
        @include flex;
        height: calc(100% - #{$navbar-height});
        padding: 1rem 4rem 2rem;
    }
    @include breakpoint(medium down) {
        padding: 1rem;
    }
    position: relative;

    .sidebar {
        @include breakpoint(large) {
            flex: 0 25%;
            height: 100%;
        }
        @include breakpoint(medium down) {
            width: 100%;
            @include flex;
            align-content: space-between;
            overflow-y: auto;
        }
        position: relative;
        align-self: stretch;
        border-radius: 10px;
        background: $background-cards;
        padding: 1.75rem 0.5rem 1.75rem 1.25rem;
        box-shadow: 5px 5px 10px 1px $box-shadow;
        .item {
            @include flex;
            align-items: center;
            color: $color-menu;
            text-decoration: none;
            font-weight: bold;
            padding: 0.75rem 1rem;
            border-radius: 5px;
            font-size: rem-calc(14);
            &:not(.bottom) {
                .icon {
                    width: 1.5rem;
                    margin: 0 1rem;
                    opacity: 0.5;
                }
            }
                
            &.active {
                background: $background-login;
                color: $white;
                .icon {
                    filter: brightness(0) invert(1);
                }
            }
            &.bottom {
                @include breakpoint(large) {
                    bottom: 0;
                    position: absolute;
                }
                .icon {
                    width: 1.5rem;
                    margin: 0 1rem;
                }
            }
        }
    }

    .content {
        overflow: auto;
        @include breakpoint(large) {
            flex: 0 75%;
            padding: 0 0 0 2rem;
        }

        @include breakpoint(medium down) {
            padding: 1rem 0;
        }
    }
}
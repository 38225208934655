$background-login: #001A5A;
$text: #001A5A;
$text-50: rgba(0, 26, 90, 0.5);
$color-menu: #001A5A;
$blue-light: #00a2ff;
$background-login-ligth: $blue-light;
$box-shadow: rgba(196, 200, 208, 0.4);
$background-input: rgba(0, 26, 90, 0.06);
$background-input-12: rgba(0, 26, 90, 0.12);
$background-hover-row: rgba(0, 26, 90, 0.70);
$background-input-placeholder: rgba(0, 26, 90, 0.36);
$font-family: 'Breew02';
$background-cards: #EEEEEE;
$navbar-height: 130px;
$yellow: #F4DB50;
$green: #1BC167;
$red: #ED4C4C;
$background-input-disabled: #c4c8d0;
$progress-height: 0.5rem;
$meter-background: rgba(0, 162, 255, 0.1);
$progress-background: rgba(0, 162, 255, 0.1);
$progress-meter-background: $blue-light;

@import "./variables";
@import "~foundation-sites/scss/foundation.scss";

.container_card {
  padding: 0.5rem;
  overflow: hidden;
  @include breakpoint(large) {
    width: 33%;
  }
  @include breakpoint(medium only) {
    width: 50%;
  }

  @include breakpoint(small down) {
    width: 100%;
  }

  .card {
    border-radius: 10px;
    background: $background-cards;
    padding: 1rem;
    color: $color-menu;
    box-shadow: 5px 5px 10px 1px $box-shadow;

    .title {
      font-size: rem-calc(18);
      margin-bottom: 1rem;
    }

    .amount {
      @include flex;
      align-items: center;
      margin-bottom: 1.6rem;
      p {
        font-size: rem-calc(12);
      }
      h4 {
        margin: 0 1rem;
        font-size: rem-calc(30);
        font-weight: normal;
      }
    }
  }
}
